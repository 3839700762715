// boilerplate for tsx component

import React from "react";

interface Props {
   centered?: boolean;
}

export default function (props: Props) {
   const { centered = true } = props;
   const logoStyle = {
      width: "130px",
      height: "60px",
      margin: centered ? "auto" : 0,
      display: "block",
      borderRadius: 10,
      padding: 4,
      backgroundColor: "white",
   };
   return (
      <>
         <img src='/assets/img/logo.png' style={logoStyle} />
      </>
   );
}
