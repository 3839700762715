import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const logoStyle = {
   width: "22px",
   height: "22px",
   margin: "auto",
   marginLeft: -18,
   display: "block",
   borderRadius: 10,
   padding: 4,
   backgroundColor: "white",
};

export default function DashboardButton() {
   const navigate = useNavigate(); // Move useNavigate inside the functional component

   const handleClick = () => {
      navigate("/");
   };

   return (
      <Button onClick={handleClick} style={{ width: 33 }}>
         <img src='/apple-touch-icon.png' alt='Logo' style={logoStyle} />
      </Button>
   );
}
